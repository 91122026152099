import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/Layout';

const ContactList = styled.ul`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-content: start;
  li {
    padding: 10px;
    display: flex;
    justify-content: end;
    align-content: baseline;
    flex-direction: row-reverse;
    @media (min-width: 768px) {
      justify-content: start;
      flex-direction: row;
    }
    a {
      text-decoration: underline;
      padding: 10px;
    }
    img {
      width: 32px;
      height: 32px;
      object-fit: scale-down;
    }
  }
`;

const {
  email,
  blog,
  github,
} = {"_id":{"$oid":"5dd079b51c9d440000a4d617"},"email":"hello@grantglidewell.com","github":"https://github.com/grantglidewell","blog":"https://blogwell.io"}

const Contact = () => {
  return (
    <Layout title='Contact'>
      <ContactList>
        <li>
          <img src='/mail-512.jpg' alt='Email' />
          <a href={`mailto:${email}`}>{email}</a>
        </li>
        <li>
          <img src='/GitHub-Mark-32px.png' alt='GitHub' />
          <a href={github}>{github}</a>
        </li>
        <li>
          <img
            src='/pencil-modify-write-edit-change-writing-circle-512.png'
            alt='GitHub'
          />
          <a href={blog}>{blog}</a>
        </li>
        <li>
          <img src='/yt_icon_mono_light.png' alt='GitHub' />
          <a href='https://www.youtube.com/channel/UCTUuyVBYiopWoTEJu6ptDbg'>
            YouTube
          </a>
        </li>
      </ContactList>
    </Layout>
  );
};

export default Contact;